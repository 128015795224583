.painter_tools_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 66px;
    width: 232px;
    height: 100px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 7px 26px rgba(44, 71, 108, 0.1);
    z-index: 5;
}

@media screen and (max-width: 1024px) {
    .painter_tools_container {
        display: flex;
        flex-direction: column;
        /* TRANSFORM USED FOR FIX BUG ON IOS DEVICES*/
        position: fixed;
        position: -ms-device-fixed;
        top: 60px;
        left: -90px;
        width: 232px;
        height: 100px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 7px 26px rgba(44, 71, 108, 0.1);
        z-index: 5;
    }
}

.painter_tools_size {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
}

.painter_tools_size_icon {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 76%;
    background-position: center;
}

.painter_tools_size_icon_block {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
}

.painter_tools_size_icon_block:hover {
    background-color: #2c476c;
    transition: ease-in-out 0.2s;
}

.painter_tools_size_icon_block:hover.painter_tools_size_icon_block img {
    filter: brightness(0) invert(1);
}

.painter_tools_color_item {
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 1;
}

.painter_tools_color_item:hover {
    border: 2px solid white;
}

.painter_tools_color_item_container span {
    width: 22px;
    height: 22px;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
    left: 80.5%;
    top: 0;
    background: #fff;
}

.color_circle {
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.painter_tools_color_item_container {
    padding: 10px 12px;
    background: #fff;
    position: absolute;
    width: 228px;
    height: 180px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 1;
    top: 108px;
    left: -20px;
    box-shadow: 0px 7px 26px rgba(44, 71, 108, 0.1);
}

.painter_tools_color {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-end-end-radius: 10px;
    border-end-start-radius: 10px;
    padding: 8px 16px;
    z-index: 2;
}

.painter_tools_color_data_item {
    padding: 1px;
    border-radius: 50px;
    box-sizing: border-box;
    width: 25px;
    height: 25px;
}

@media (max-width: 1024px) {
    .painter_tools_size_icon{
        background-size: 60%;
    }
    .color_circle {
        width: 20px;
        height: 20px;
    }

    .color_circle svg {
        width: 18px;
        height: 18px;
    }

    .painter_tools_color_item {
        width: 15px;
        height: 15px;
    }
}
