@font-face {
  font-family: 'Bokonique';
  src: url("./assets/fonts/Bokonique-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto';
  src: url("./assets/fonts/Mardoto-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mardoto';
  src: url("./assets/fonts/Mardoto-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Armenian';
  src: url('./assets/fonts/NotoSansArmenian-Bold.woff2') format('woff2'),
      url('./assets/fonts/NotoSansArmenian-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Armenian';
  src: url('./assets/fonts/NotoSansArmenian-Medium.woff2') format('woff2'),
      url('./assets/fonts/NotoSansArmenian-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Armenian';
  src: url('./assets/fonts/NotoSansArmenian-Light.woff2') format('woff2'),
      url('./assets/fonts/NotoSansArmenian-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Armenian';
  src: url('./assets/fonts/NotoSansArmenian-Regular.woff2') format('woff2'),
      url('./assets/fonts/NotoSansArmenian-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Noto Sans Armenian';
  src: url('./assets/fonts/NotoSansArmenian-Black.woff2') format('woff2'),
      url('./assets/fonts/NotoSansArmenian-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
      url('./assets/fonts/AvenirNextLTPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
      url('./assets/fonts/AvenirNextLTPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./assets/fonts/AvenirNextLTPro-It.woff2') format('woff2'),
      url('./assets/fonts/AvenirNextLTPro-It.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('./assets/fonts/AvenirNext-Medium.woff2') format('woff2'),
      url('./assets/fonts/AvenirNext-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'GheaGrapalat';
  src:url('./assets/fonts/GHEAGrpalat.woff2') format('woff2'),
      url('./assets/fonts/GHEAGrpalat.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lucida Grande';
  src: url('./assets/fonts/LucidaGrande.woff2') format('woff2'),
      url('./assets/fonts/LucidaGrande.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lucida Grande';
  src: url('./assets/fonts/LucidaGrande-Bold.woff2') format('woff2'),
      url('./assets/fonts/LucidaGrande-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


:root {
  --main-color: #2c476c;
  --dynamic-screenshot-color:#FF6539;
  --category-color: #fff;
  --loader-color:#ececec;
  --loader-color-2: #cbcacac4;
}
body {
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  font-family: 'Noto Sans Armenian', sans-serif;
  color: var(--main-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

a, button, input {
  font: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

input {
  font: inherit;
  color: inherit;
}

ul {
  list-style: none;
}

img {
    width: 100%;
    height: 100%;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

iframe {
  width: 100%;
  height: 100%;
}

svg {
  pointer-events: none;
}
svg.icon {
  strokeWidth: 1.5px;
  strokeDasharray: 0 0;
}
.with-svg {
  display: flex;
  align-items: center;
  width: 28px;
  height: 28px;
  margin-right: calc(100vw/60.7);
}
.with-svg svg {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}
#root {
  height: 100%;
  z-index: 1;
  position: relative;
}
#popper{
  z-index: 1;
}


/* Map Mobile Mode */

.asas{
  position: absolute;
  bottom: -110px;
}

.hints_lamp_popup_lg{
  position: absolute;
  bottom: -110px;
  z-index: 1000;
  border-radius: 10px;
}

#article-read-more-mobile{
  display: none;
}
.map-svg{
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #7396a6;
  display: grid;
  perspective: 800px;
  justify-content: center;
  align-content: center;
}
.map-svg svg{
  width: 145vw;
  aspect-ratio: 1/1;
}
.map-svg svg path{
  position: relative;
  z-index: 1;
  transform-style: preserve-3d;
}
.map-svg #BR,.map-svg #TD,.map-svg #UA,.map-svg #SS{
  animation: countryAnimation 2s ease-in-out infinite;
  z-index: 2;
  transform-origin: 50% 50% 0px;
  animation-name: countryAnimation, bgrandom;
  animation-duration: 4s, 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.map-svg #BR{
  animation-delay: 0s;
}
.map-svg #SS{
  animation-delay: 0.4s;
}
.map-svg #UA{
  animation-delay: 0.7s;
}
.map-svg #TD{
  animation-delay: 1s;
}
[data-hidd="true"] {
    display: none;
    overflow: hidden;
    contain: content;
}
@keyframes countryAnimation {

  25% {
    transform: rotate3d(2, 0, 1, -4deg);
  }
  100% {
    transform: rotate3d(2, 0, 1, 0deg);
  }
}
@keyframes bgrandom {
  0% { fill: white }
  50% { fill: red }
  100% { fill: white }
}


@media screen and (max-width: 1024px) and (orientation: landscape) {
  #mobile_mode_main_tab_return{
    /* display: none; */
    margin-top: 0;
  }
    .main__right.right-side .main__tab.compass-box{
      margin-bottom: 0;
    }


        .main {
          position: relative;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          max-height: calc(100vh - 124px);
          pointer-events: none;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          /*z-index: 99;*/
        }

        .main>* {
          pointer-events: all;
        }

   .header {
      zoom: unset;
    }

    .header__right {
      display: none;
    }

    .header_right_mobile {
      /*background-color: #2C476C;*/
      position: absolute;
      right: calc(15px / 0.7);
      top: calc(15px / 0.7);
      border-radius: calc(5px / 0.7);
      z-index: 4;
    }

    .mobile_menu_burger {
      /*background-image: url(./assets/imgs/mobile/Burger_Menu.png);*/
      background-repeat: no-repeat;
      background-size: calc(16px / 0.7) calc(12px / 0.7);
      background-position: center;
      background-color: #FFFFFF;
      border-radius: calc(5px / 0.7);
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mobile_menu_burger img {
      width: 16px;
      height: 12px;
    }

    .mobile_menu_buttons {
      padding: calc(15px / 0.7) 0px;
      /* padding-right: calc(32px / 0.7); */
      display: flex;
      justify-content: flex-end;
    }

    .mobile_menu_buttons_content {
      display: flex;
      align-items: baseline;
    }

    .right-side__search-content:after {
      content: "";
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0px);
      height: 2px;
      width: 94%;
      border-bottom: 2px solid #2c476c;
      opacity: 0.5;
      z-index: 100;
    }

    .right-side__search-content {
      position: relative;
      top: 0px;
      left: 0px;
      /* transform: translateX(-52%); */
      background-color: #fff;
      border-radius: max(10px, calc(100vh / 108.1));
      overflow: hidden;
      width: max(83%, calc(100vw / 3.89));
      min-width: 100vw;
      min-height: 60px;
      overflow: initial;
      z-index: 0;
    }



    .right-side__search-content .with-svg {
      width: 100%;
      height: 100%;
    }

    .mobile_menu_close_icon {
      color: rgb(255, 255, 255);
      position: absolute;
      /* right: calc(15px / 0.7); */
      cursor: pointer;
      width: calc(10px / 0.7);
      height: calc(10px / 0.7);
      transform: translateY(100%);
    }

    .mobile_menu_buttons_content>.right-side__search.active .right-side__search-content {
      /* height: auto; */
      width: max(100%, calc(406vw / 3.89));
      aspect-ratio: none;
      min-height: 60px;
      overflow: initial;
      z-index: 0;
      min-width: calc(234px / 0.7);
    }

    .right-side__search.active {
      position: absolute;
      right: 70px;
    }

    .right-side__search-response {
      position: absolute;
      height: 275px;
      width: max(100%, calc(100vw / 3.89));
      top: 51px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border-radius: 0px 0px 11px 11px;
      background-color: #fff;
      z-index: 98;
      padding: 20px 10px;
    }

    .right-side__filter-box {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 49%;
      right: 70px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .right-side__filter-icon {
      z-index: 99 !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      cursor: pointer !important;
    }

    .right-side__filter-box svg {
      z-index: 9999;
      width: 24px;
      height: 24px;
      stroke: #000;
    }

    #button_color svg {
      stroke: #000 !important;
    }


    .right-side__search-input {
      display: block;
      height: 50px;
      width: 100%;
    }

    .right-side__search-input {
      padding: 0 75px 0 0;
    }

    .right-side__search-input::placeholder {
      opacity: 0.4;
      color: rgba(44, 71, 108, 1);
      font-family: 'Noto Sans Armenian', sans-serif;
      font-size: max(18px, calc(100vh / 81.89));
      font-weight: 400;
      font-style: normal;
      letter-spacing: min(2px, calc(100vh / 818.9));
      text-align: left;
    }

    .mobile_menu_buttons_content>.right-side__filters {
      /* overflow: hidden; */
    }

    .mobile_menu_buttons_content>.right-side__filters.filters.active .filters__content {
      margin-top: 0px;
      /* width: calc(196px /0.7); */

    }

    #is_base_map_dark{
      width: calc(411px / 0.7) !important;
      height: calc(66px / 0.7) !important;
    }

    #is_not_base_map_dark {
      width: calc(377px / 0.7) !important;
      height: calc(66px / 0.7) !important;
    }

    .mobile_menu_buttons_content>.right-side__filters .filters__content {
      right: 0px;
      top: 0px;
    }

    .sss {
      background-color: #2C476C !important;
      border: 1px solid #fff;
      box-sizing: border-box;
      width: calc(36px / 0.7) !important;
      height: calc(36px / 0.7) !important;
    }


    .right-side__filters.filters.main__tab {
      width: calc(36px / 0.7) !important;
      height: calc(36px / 0.7) !important;
    }

    .right-side__toolbox.main__tab.active:has(.paintBar_container){
      display: flex;
      flex-direction: row-reverse;
      width: inherit!important;
      gap: 10px;
      /*height: inherit!important;*/
      align-items: start;
    }
    .paintBar_container {
        right: 47px;
        top: -10px;
        width: fit-content;
        max-width: calc(100vw - 37px) !important;
        position: absolute;
        display: flex;
        background: white;
        height: fit-content;
        padding: 10px;
        border-radius: 10px;
        max-width: 70vw;
        overflow-x: auto; /* Enable vertical scrolling */
        overflow-y: hidden;
        -webkit-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.2);
        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.2);
        z-index: 3;
    }

    .pain_items {
      width: calc(36px / 0.7);
      user-select: none;
    }

    .pain_items > img {
        user-select: none;
    }


    #screen-shot-icon {
      display: none;
    }

    .icon_img {
      width: 20px !important;
    }

    #main_tab_icon_img {
      width: 18px;
      height: auto;
    }

    .right-side__filters .filters__radio {
      gap: -17px;
      width: 100% !important;
    }


    .right-side__filters .filters__radio-text {
      width: fit-content;
      overflow: hidden;
      margin: 0 auto;
      font-style: normal;
      font-weight: 600;
      font-size: calc(10px / 0.7);
      line-height: 14px;

      display: flex;
      align-items: center;
      text-align: center;

      color: #2C476C;
      margin-top: 4px;
    }

    .filters__radio-label__img-box {
      margin: 0px auto;
      width: calc(39.17px / 0.7);
      height: calc(37.5px / 0.7);
    }

    .filters__radio-label__img-box img {
      width: calc(30px / 0.7) !important;
      height: calc(30px / 0.7) !important;
    }

    .filters__radio-text {
      font-style: normal;
      font-weight: 600;
      font-size: calc(10px / 0.7);
      line-height: 14px;
      margin-top: 4px;
    }

    .right-side__filters.filters.active .filters__content {
      padding: 10px 10px;
    }

    .painter_tools_color_item_container {
      width: 288px;
      top: 113px;
      left: 0px;
    }

    .painter_tools_color_item_container span {
      left: 88.3%
    }

    .mobile_menu_buttons_content .right-side__search-filters {
      width: max(200px, calc(100vw / 6.98));
      left: 0px;
      width: calc(234px / 0.7);
      padding-left: 30px;
      /*height: calc((100vh - 174px) / 0.7) !important;*/
      height: 230px;
      width: 100%;
      top: 40px;
    }

    .mobile_menu_buttons_content .right-side__search-filters>li {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      padding-left: 15px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }

    .right-side__filters-btn {
      font-size: 16px;
      display: flex;
      align-items: center;
      width: 100%;
      margin-right: 0;
      font-weight: 400;
      color: rgba(44, 71, 108, 1);
    }

    .right-side__filters-btn img,
    .right-side__filters-btn svg {
      width: 19px;
      height: 19px;
      margin-right: 10px;
      stroke: var(--main-color);
    }

    .search_menu_filter_item button span {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 19px;
    }

    .right-side__search-filters:after {
      content: none;
      position: absolute;
      top: -3px;
      transform: rotate(45deg);
      left: 91.6%;
      width: 30px;
      height: 30px;
      background-color: white;
      z-index: -1;
    }

    #button_color {}

    img.activeState {
      width: calc(13px / 0.7);
      height: calc(14px / 0.7);
    }

    .right-side__search-content:after {
      width: 97%;
      position: absolute;
    }

    .right-side__search-field {
      background-color: white;
      z-index: 99;
      min-height: 60px;
      position: relative;
      width: 100%;
      left: 50%;
      transform: translate(-50%, 0px);
      padding: 0px 10px;
      box-shadow: 0px 7px 26px rgba(44, 71, 108, 0.1);
      opacity: 1;
      border-radius: 11px;
      padding-left: 20px;
    }


    .button_active {
      border: 1px solid #fff;
    }

    .right-side__filters .filters__radio-label {
      overflow: unset;
    }

    .filters__radio-label input:checked+.filters__radio-label__img-box.light-mode-icon {
      background-image: url(./assets/imgs/mobile/sun.svg);
      background-size: calc(18px / 0.7);
    }

    .filters__radio-label__img-box.dark-mode-icon {
      background-image: url(./assets/imgs/mobile/moon.svg);
      background-size: calc(16px / 0.7);
    }

    .mode-switch-box {
      justify-content: space-between;
    }

    .header_burger_mobile {
      position: absolute;
      left: calc(15px / 0.7);
      top: calc(15px / 0.7);
      border-radius: calc(6px / 0.7);
      width: calc(36px / 0.7);
      height: calc(36px / 0.7);
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(16px / 0.7);
      color: #2C476C;
    }
    .right-side__filters.filters.main__tab.active:has(.base-map){
      display: flex;
      flex-direction: row-reverse;
      width: inherit!important;
      gap: 10px;
      height: inherit!important;
      align-items: start;
    }
    .right-side__filters.filters.main__tab.active:has(.base-map) .main__tab-btn{
      margin: 0!important;
    }
    .base-map {
      width: calc(196px / 0.7) !important;
      min-height: calc(66px / 0.7) !important;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: calc(10px / 0.7);
      position: relative!important;
    }


    .right-side__search-filters:before {
      height: 0px;
    }

    .filters__radio-label__img-box.mode.light-mode-icon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .filters__radio-label__img-box.mode.dark-mode-icon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
    }

    .right-side__toolbox,
    .right-side__search,
    .right-side__filters {
      margin-right: calc(10px / 0.7) !important;
    }

    .pain_items{
      margin: 0;
      margin-right: calc(15px / 0.7);
    }

    .painter_tools_color_item_container{
      width: calc(255px / 0.7);
      height: 180px;
      top: 120px;
      padding: 10px;
    }


    .painter_tools_size_icon_block{
      border-radius: 50px;
      width: 30px;
      height: 30px;
    }

    .painter_tools_color_data_item > div > svg{
      width: 20px;
      height: 20px;
    }

    .painter_tools_size{
      justify-content: space-around;
      padding: 5px;
    }

    .painter_tools_color {
      padding: 8px 10px;
    }

    .right-side {
      width: 60px !important;
      top: 30% !important;
      transform: translateY(-54%) !important;
    }

    .right-side {
      width: 36px !important;
      top: 50% !important;
      transform: translateY(-54%) !important;
      /* zoom: 70%; */
      right: 15px;
    }

    #mobile_mode_main_tab_zoom_minus {
      height: 36px !important;
    }

    #mobile_mode_main_tab_zoom_minus>.zoom-btn>.main__tab-icon.icon-wrapper svg {
      width: 18px !important;
      height: 18px !important;
    }

    #mobile_mode_main_tab_zoom_plus {
      height: 36px !important;
    }

    #mobile_mode_main_tab_zoom_plus>.zoom-btn>.main__tab-icon.icon-wrapper svg {
      width: 18px !important;
      height: 18px !important;
    }

    #mobile_mode_main_tab_return {
      height: 36px !important;
    }

    #mobile_mode_main_tab_return>.return-nav-btn>span svg {
      width: 18px !important;
      height: 18px !important;
    }



    .zoom-btn {
      border-radius: 5px;
    }

    .right-side {
      margin: 0px !important;
    }

        /* Sidebar and Category */
        .clock-icon{
          left: 270px!important;
        }
        .main__left.left-side {
          /*zoom: 70% !important;*/
          top: 40px !important;
          left: 0px;
          margin: 0 !important;
          z-index: 98;
          margin-top: 46px;
          position: relative;
          width: max-content;
          margin-left: max(calc(100vw / 384), 5px);
          gap: calc(100vh / 369.94);
          display: flex;
          flex-direction: column;
          height: -moz-fit-content;
          height: fit-content;
        }

        .main__tab-btn,.account .header__burger {
          width: 36px !important;
          height: 36px !important;
        }
        .account .header__burger{
            font-size: 16px;
        }
        .categories-collapse.main__tab {}

        .filters__btn.main__tab {
          width: 36px;
          height: 36px;
        }

        .hints__btn.main__tab.hints__btn_lamp {
          width: 36px;
          height: 36px;
        }

        .hints__btn.main__tab.hints__btn_quiz {
          width: 36px;
          height: 36px;
        }

        .main__subitem-heading,
        .hints__content_item {
          height: 36px;
        }

        .main__subitem {
          left: 60px;
        }

        .main__tab img,
        .main__tab svg {
         scale: 0.6;
        }
        .main__tab.persons.active .main__tab-btn:after {
          width: 92px;
        }

        .main__tab.active .main__subitem {
          width: calc(235px / 0.7);
        }

        .main__subitem-libtn,
        .main__subitem-btn_lamp {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;

          color: #2C476C;
          margin-left: 4px;
        }

        .main__subitem-label {
          left: 290px
        }
        .main__subitem-clock {
          left: 298px;
        }

        .hints__content_item {
          min-height: 36px !important;
          width: calc(249px / 0.7) !important;
        }

        .hints.active .hints__content {
          width: calc(249px / 0.7) !important;
          height: 243px !important;
        }

        .lamp_header_icon {
          left: 260px;
        }

        .hints__content {
          max-height: none !important;
        }

        .left-side__bottom .filters__content {
          left: -210px;
        }

        .sort-board[data-active="true"] {
          left: 53%;
        }

        .sort-board:after {
          left: 110px;
        }

        .sorted_tooltip {
          left: 265px;
        }

        .sorted_tooltip_arrow {
          left: 57%;
        }

        .main__subitem-items_lamp {
          /* max-height: 150px !important; */
        }


        .left-side__bottom .filters__radio-text {
          font-size: 12px !important;
          padding-top: 1.5px !important;
          padding-bottom: 4px !important;
        }

      .hints_lamp_popup_sm {
        position: absolute;
        bottom: -190px;
        zoom: 70%;
          -moz-transform: scale(0.7);
          z-index: 1;
      }


      /* ArticleReadMore */

         #article-read-more {
           display: none !important;
         }

         #article-read-more-mobile {
           display: grid !important;
         }

         #article-read-more-mobile>.article-read-more__body {
           display: inline-flex;
         }

         .article-read-more-header__title h2 {
           font-style: normal;
           font-weight: 800;
           font-size: calc(16px / 0.7);
           color: #2C476C;
         }

         .article-read-more-header__title #like {
           width: calc(36px /0.7);
           height: calc(36px /0.7);
         }

         .article-read-more-header__title #like label {
           width: calc(36px /0.7);
           height: calc(36px /0.7);
         }

         .raed__more__font {
             align-self: flex-start;
         }

         .article-read-more-header__close svg {
           width: calc(12px /0.7);
           height: calc(12px /0.7);
         }

         /* .article-read-more-body__right-bar {
           width: 100% !important;
           max-width: 100% !important;
           padding: 15px 0px;
         } */

         .article-read-more-body-main__collaps-content>div div {
           flex-wrap: wrap;
           gap: 0;
           justify-content: center;
           font-size: var(--inner-div-font-size);
           width: 100%;
         }

         .article-read-more-body-main__collaps-content>div div label {
          width: 42%;
            margin: 4px 5px;
         }

         .article-read-more-content {
           padding: 0px;
         }

         .article-read-more-body__main {
           display: grid;
           gap: 20px;
         }

        .article-read-more__body .Collapsible{
          margin-bottom: 20px;
        }

      .article-read-more__header{
        padding: 20px 20px;
      }
             .articles-grid {
               z-index: 1000;
               zoom: 70%;
                 -moz-transform: scale(0.7);
               margin-top: 60px;
               margin-right: calc(15px / 0.7);
               display: flex;
               flex-direction: column;
               max-height: fit-content;
             }

      .minimized .hero__info-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .hero.minimized {
        position: inherit;
      }

      .hero__title {
        min-width: 160px;
        font-size: 10px;
      }
    #splide02 {
      /* margin-top: 20px !important; */
    }

  .gallery-content {
      margin-top: 20px !important;
    /* grid-template-columns: 90vw;
      grid-template-areas:
        "m"
        "i"
        "c"; */
    }
    .splide.gallery-main.splide {
      height: 66vh !important;
    }

    .splide.gallery-main.splide>.splide__track {
          height: 66vh !important;
    }

    .splide.gallery-main.splide>.splide__track>ul>li {
            height: 66vh !important;
    }

    #group_content {
      height: 35px;
    }

  @media screen and (max-width: 1025px) {
    #group_content {
      display: none;
    }

  }


    .timeline__arrow,
    .left_topic_arrow_icon,
    #topic_title,
    .right_topic_arrow_icon {
      display: none !important;
    }

    .timeline.expand .vis-panel.vis-center {
      overflow-y: unset !important;
    }

    .timeline {
      height: 66px;
    }

    /*.scroll_and_line_change {*/
    /*  bottom: 30px;*/
    /*  right: -33px;*/
    /*}*/

    .timeline .vis-custom-time div:first-child::after {
      bottom: 189px;
    }

    footer.timeline:not(.expand)>.timeline__content>.timeline_start_porsition_section {
      margin-top: 0px !important;
    }

    /*.vis-custom-time.t1 {*/
    /*  top: -1px !important;*/
    /*}*/

    .mobile_main_tab {
      width: 36px !important;
      height: 36px !important;
    }

    #timeline_extend_button{
      display: none;
    }

  /*.scroll_and_line_change_buttons_plus, .scroll_and_line_change_buttons_minus {*/
  /*  display: none;*/
  /*}*/
  .hero{
    top: -38px;
    right: 88px;
  }
  .articles-grid {
  /*  margin-right: 88px;*/
    margin-top: 10px;
    margin-right: 30px;
  }

  .main__left.left-side,.main__tabs-wrapper, ul.main__tabs.left-side__tabs,.left-side__bottom{
    flex-direction: row;
  }

  .categories-collapse.main__tab,.main_tab_mobile_landscape,.filters__btn.main__tab,.hints__btn.main__tab.hints__btn_lamp,.hints__btn.main__tab.hints__btn_quiz{
    margin: 0;
    margin-right: 10px;
  }

  .main__subitem{
    top:40px !important;
    left:0 !important;
  }

  .main__tab.persons.active .main__tab-btn:after{
    height: 50px;
    width: 36px;
  }

  .main__subitem-heading,
  .hints__content_item{
    -webkit-box-shadow: 0px -3px 8px 1px rgba(0, 0, 0, 0.24) !important;
      box-shadow: 0px -3px 8px 1px rgba(0, 0, 0, 0.1) !important;
  }



  .left-side__bottom .filters__content{
    left: -210 !important;
    top: 54px !important;
  }
  .hints__content{
    left: 0 !important;
    top: 54px !important;
  }

  .main__subitem-items_lamp {
    max-height: 150px !important;
    height: 100%;
  }

  .left-side__hints.hints.active .hints__btn_quiz:after{
    height: 92px;
    width: 52px;
  }
  .hints__content_itemswrapper_quiz .main__subitem-items_quiz{
    max-height: calc(100dvh - 305px) !important;
    height: 100%;
  }
  .sorted_tooltip{
      top: 86px;
      left: 165px;
  }
  .hints_lamp_popup_sm {
    position: absolute;
    bottom: -155px;
    zoom: 70%;
      -moz-transform: scale(0.7);
    /*left: 395px;*/
  }
  .hints_lamp_popup_landscape_sm {
    position: absolute;
    bottom: -120px;
    zoom: 70%;
    -moz-transform: scale(0.7);
    left: 0px;
  }
  .hints_lamp_popup_landscape_menu_open_sm {
    position: absolute;
    bottom: -120px;
    zoom: 70%;
    -moz-transform: scale(0.7);
    left: 395px;
      z-index: 1;
  }

  .hints__content {
    left: -318px !important;
    top: 40px !important;
  }
  .right-to-left .hints__content{
    left: 0px !important;
  }
  .left-to-right .hints__content{
    left: -281px !important;
    border-radius: 12px 0px 12px 12px;
  }
  .left-to-right .hints__content.quiz__content{
    left: -263px !important;
  }
  .zoom-btns{
    display: none;
  }

  .main__left.left-side{
    top: 0 !important;
    width: inherit;
      -moz-transform: scale(1);
  }

  .timeline {
    height: 58px;
  }

 .ruler {
   z-index: 999 !important;
 }
 .gallery.active{
  zoom: 76% !important;
 }
 .gallery-header {
  /* width: 92% !important; */
 }
    .hints__content_itemswrapper_lamp .main__subitem-items {
        max-height: calc(100dvh - 310px) !important;
    }
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
    .hints__content_itemswrapper_lamp .main__subitem-items{
        max-height: calc(100dvh - 590px) !important;
    }

  .main__tab.dynamic-screenshot__main-tab .presentation__main-tab {
    display: none;
  }


  .header {
  /*  zoom: 70%;*/
  }

  .header__right {
    display: none;
  }

  .header_right_mobile {
    /*background-color: #2C476C;*/
    position: absolute;
    right: calc(15px / 0.7);
    top: 31px;
    border-radius: calc(5px / 0.7);
    z-index: 4;
  }

  .mobile_menu_burger {
    /*background-image: url(./assets/imgs/mobile/Burger_Menu.png);*/
    background-repeat: no-repeat;
    background-size: calc(16px / 0.7) calc(12px / 0.7);
    background-position: center;
    background-color: #FFFFFF;
    border-radius: calc(5px / 0.7);
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile_menu_burger img {
    width: calc(16px / 0.7);
    height: calc(12px / 0.7);
  }

  .mobile_menu_buttons {
    padding: calc(15px / 0.7) 0px;
    /* padding-right: calc(32px / 0.7); */
    display: flex;
    justify-content: flex-end;
  }

  .mobile_menu_buttons_content {
    display: flex;
    align-items: center;
  }

  .right-side__search-content:after {
    content: "";
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0px);
    height: 2px;
    width: 94%;
    border-bottom: 2px solid #2c476c;
    opacity: 0.5;
    z-index: 100;
  }

  .right-side__search-content {
    position: relative;
    top: 0px;
    left: 0px;
    /* transform: translateX(-52%); */
    background-color: #fff;
    border-radius: max(10px, calc(100vh / 108.1));
    overflow: hidden;
    width: max(83%, calc(100vw / 3.89));
    min-width: 100vw;
    min-height: 60px;
    overflow: initial;
    z-index: 0;
  }



  .right-side__search-content .with-svg {
    width: 100%;
    height: 100%;
  }

  .mobile_menu_close_icon {
    color: rgb(255, 255, 255);
    position: absolute;
    /* right: calc(15px / 0.7); */
    cursor: pointer;
    width: calc(10px / 0.7);
    height: calc(10px / 0.7);
  }

  .mobile_menu_buttons_content>.right-side__search.active .right-side__search-content {
    /* height: auto; */
    width: max(100%, calc(406vw / 3.89));
    aspect-ratio: none;
    min-height: 60px;
    overflow: initial;
    z-index: 0;
    min-width: calc(234px / 0.7);
  }

  .right-side__search.active {
    position: absolute;
    right: 70px;
  }

  .right-side__search-response {
    position: absolute;
    height: 275px;
    width: max(100%, calc(100vw / 3.89));
    top: 51px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 0px 0px 11px 11px;
    background-color: #fff;
    z-index: 98;
    padding: 20px 10px;
  }

  .right-side__filter-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 49%;
    right: 70px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .right-side__filter-icon {
    z-index: 99 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
  }

  .right-side__filter-box svg {
    z-index: 9999;
    width: 24px;
    height: 24px;
    stroke: #000;
  }

  #button_color svg {
    stroke: #000 !important;
  }


  .right-side__search-input {
    display: block;
    height: 50px;
    width: 100%;
  }

  .right-side__search-input {
    padding: 0 75px 0 0;
  }

  .right-side__search-input::placeholder {
    opacity: 0.4;
    color: rgba(44, 71, 108, 1);
    font-family: 'Noto Sans Armenian', sans-serif;
    font-size: max(18px, calc(100vh / 81.89));
    font-weight: 400;
    font-style: normal;
    letter-spacing: min(2px, calc(100vh / 818.9));
    text-align: left;
  }

  .mobile_menu_buttons_content>.right-side__filters {
    /* overflow: hidden; */
  }

  .mobile_menu_buttons_content>.right-side__filters.filters.active .filters__content {
    margin-top: 0px;
    /* width: calc(196px /0.7); */
  }

  .mobile_menu_buttons_content>.right-side__filters .filters__content {
    right: 0px;
    top: 0px;
  }

  .sss {
    background-color: #2C476C !important;
    border: 1px solid #fff;
    box-sizing: border-box;
    width: calc(36px / 0.7) !important;
    height: calc(36px / 0.7) !important;
  }


  .right-side__filters.filters.main__tab {
    width: calc(36px / 0.7) !important;
    height: calc(36px / 0.7) !important;
  }
  .right-side__toolbox.main__tab.active:has(.paintBar_container){
    display: flex;
    flex-direction: row-reverse;
    width: inherit!important;
    gap: 0px;
    /*height: inherit!important;*/
    align-items: start;
  }

  .paintBar_container {
    right: 47px;
    top: -10px;
    width: calc(100vw - 37px) !important;
    position: absolute;
    display: flex;
    background: white;
    height: fit-content;
    padding: 10px;
    border-radius: 10px;
    max-width: 70vw;
    overflow-x: auto; /* Enable vertical scrolling */
    overflow-y: hidden;
    -webkit-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.2);
  }

  .pain_items {
    width: calc(36px / 0.7);
  }

  #screen-shot-icon {
    display: none;
  }

  .icon_img {
    width: 20px !important;
  }

  #main_tab_icon_img {
    width: 18px;
    height: auto;
  }

  .right-side__filters .filters__radio {
    gap: -17px;
    width: 100%;
      display: flex;
      justify-content: space-around;
  }


  .right-side__filters .filters__radio-text {
    width: 40px;
    overflow: hidden;
    margin: 0 auto;
  }

  .filters__radio-label__img-box {
    margin: 0px;
    width: calc(32.17px / 0.7);
    height: calc(30.5px / 0.7);
  }

  .filters__radio-label__img-box img {
    width: calc(25px / 0.7) !important;
    height: calc(25px / 0.7) !important;
  }

  .filters__radio-text {
    font-style: normal;
    font-weight: 600;
    font-size: calc(10px / 0.7);
    line-height: 14px;
    margin-top: 4px;
  }

  .right-side__filters.filters.active .filters__content {
    padding: 10px 10px;
  }

  .painter_tools_color_item_container {
    width: 288px;
    top: 113px;
    left: 0px;
  }
  .painter_tools_color_item_container span {
    left: 88%;
  }

  .mobile_menu_buttons_content .right-side__search-filters {
    width: max(200px, calc(100vw / 6.98));
    left: 0px;
    width: calc(234px / 0.7);
    padding-left: 30px;
    height: calc(227px / 0.7) !important;
    width: 100%;
    top: 55px;
  }

  .mobile_menu_buttons_content .right-side__search-filters>li {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    padding-left: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: calc(14px / 0.7);
    line-height: 19px;
  }

  .right-side__filters-btn {
    font-size: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 0;
    font-weight: 400;
    color: rgba(44, 71, 108, 1);
  }

  .right-side__filters-btn img,
  .right-side__filters-btn svg {
    width: 19px;
    height: 19px;
    margin-right: 10px;
    stroke: var(--main-color);
  }

  .search_menu_filter_item button span {
    font-style: normal;
    font-weight: 400;
    font-size: calc(14px / 0.7);
    line-height: 19px;
  }

  .right-side__search-filters:after {
    content: none;
    position: absolute;
    top: -3px;
    transform: rotate(45deg);
    left: 91.6%;
    width: 30px;
    height: 30px;
    background-color: white;
    z-index: -1;
  }

  #button_color {}

  img.activeState {
    width: calc(9px / 0.7);
    height: calc(10px / 0.7);
  }

  .right-side__search-content:after {
    width: 97%;
    position: absolute;
  }

  .right-side__search-field {
    background-color: white;
    z-index: 99;
    min-height: 60px;
    position: relative;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0px);
    padding: 0px 10px;
    box-shadow: 0px 7px 26px rgba(44, 71, 108, 0.1);
    opacity: 1;
    border-radius: 11px;
    padding-left: 20px;
  }


  .button_active {
    border: 1px solid #fff;
  }

  .right-side__filters .filters__radio-label {
    overflow: unset;
  }

  .filters__radio-label input:checked+.filters__radio-label__img-box.light-mode-icon {
    background-image: url(./assets/imgs/mobile/sun.svg);
    background-size: calc(18px / 0.7);
  }

  .filters__radio-label__img-box.dark-mode-icon {
    background-image: url(./assets/imgs/mobile/moon.svg);
    background-size: calc(16px / 0.7);
  }

  .mode-switch-box {
    justify-content: space-between;
  }

  .header_burger_mobile {
    position: absolute;
    left: calc(11px / 0.7);
    top: calc(23px / 0.7);
    border-radius: calc(6px / 0.7);
    width: calc(30px / 0.7);
    height: calc(30px / 0.7);
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(13px / 0.7);
    color: #2C476C;
  }
  .right-side__filters.filters.main__tab.active:has(.base-map){
    display: flex;
    flex-direction: row-reverse;
    width: inherit!important;
    gap: 10px;
    height: inherit!important;
    align-items: start;
  }

  .right-side__filters.filters.main__tab.active:has(.base-map) .main__tab-btn{
    margin: 0!important;
  }
  .base-map {
    width: calc(196px / 0.7) !important;
    min-height: calc(66px / 0.7) !important;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: calc(10px / 0.7);
    position: relative!important;
  }


  .right-side__search-filters:before {
    height: 0px;
  }

  .filters__radio-label__img-box.mode.light-mode-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filters__radio-label__img-box.mode.dark-mode-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
  }

  .right-side__toolbox,
  .right-side__search,
  .right-side__filters {
    margin-right: calc(10px / 0.7) !important;
  }


  .right-side {
    width: 36px !important;
    top: 50% !important;
    transform: translateY(-54%) !important;
    /* zoom: 70%; */
    right: 15px;
  }

  #mobile_mode_main_tab_zoom_minus{
    height: 36px  !important;
  }

  #mobile_mode_main_tab_zoom_minus > .zoom-btn > .main__tab-icon.icon-wrapper svg{
    width: 18px !important;
    height: 18px !important;
  }
  #mobile_mode_main_tab_zoom_plus{
    height: 36px  !important;
  }

  #mobile_mode_main_tab_zoom_plus > .zoom-btn > .main__tab-icon.icon-wrapper svg{
    width: 18px !important;
    height: 18px !important;
  }

  #mobile_mode_main_tab_return{
    height: 36px  !important;
  }

  #mobile_mode_main_tab_return > .return-nav-btn > span svg{
    width: 18px !important;
    height: 18px !important;
  }



  .zoom-btn {
    border-radius: 5px;
  }

  .right-side {
    margin: 0px !important;
    margin-top: 30px !important;
    z-index: 97;
  }


#mobile_compass_box {
  height: 36px;
}

#compass_3d_text{
  /* margin-top: 2px; */
  line-height: initial;
}

.mode-switch-box{
  height: calc(100vh / 14.5);
}



/* Sidebar and Category */


  .main__left.left-side {
    /*zoom: 70% !important;*/
    /*top: 80px !important;*/
    /*left: calc(15px / 0.7);*/
    margin: 0 !important;
    z-index: 98;
    margin-top: 46px;
    position: relative;
    width: max-content;
    margin-left: max(calc(100vw / 384), 5px);
    gap: calc(100vh / 369.94);
    display: flex;
    flex-direction: column;
    height: -moz-fit-content;
    height: fit-content;
  }




  .categories-collapse.main__tab{
  }

  .filters__btn.main__tab{
      width: 36px;
      height: 36px;
  }
  .main__tab-btn{
    width: 36px;
    height: 36px;
  }
  .hints__btn.main__tab.hints__btn_lamp{
    width: 36px;
    height: 36px;
  }

  .hints__btn.main__tab.hints__btn_quiz{
    width: 36px;
    height: 36px;
  }

  .main__subitem-heading,.hints__content_item{
    height: 36px;
  }

  .main__subitem{
    left: 60px;
  }


  .main__tab.persons.active .main__tab-btn:after {
    width: 92px;
  }

  .main__tab.active .main__subitem {
    width: calc(249px / 0.7);
  }

  .main__subitem-libtn,.main__subitem-btn_lamp{
    font-style: normal;
    font-weight: 500;
    font-size:calc(12px / 0.7);
    line-height: calc(14px / 0.7);

    color: #2C476C;
    margin-left: 4px;
  }

  .main__subitem-label {
    left: 298px;
  }
  .clock-icon{
    left: 260px!important
  }
  .hints__content_item {
    min-height: 36px !important;
    width: calc(249px / 0.7) !important;
  }

  .hints.active .hints__content {
    width: calc(249px / 0.7) !important;
    height: 243px !important;
  }

  .lamp_header_icon {
    left: 260px;
  }

  .hints__content{
    max-height: none !important;
  }

  .left-side__bottom .filters__content{
    left: 60px;
  }

  .sort-board[data-active="true"] {
    left: 58%;
  }

  .sort-board:after {
    left: 110px;
  }

  .sorted_tooltip {
    left: 265px;
  }

  .sorted_tooltip_arrow {
    left: 57%;
  }

  .main__subitem-items_lamp{
    /* max-height: 150px !important; */
  }


  .left-side__bottom .filters__radio-text{
    font-size: 12px !important ;
    padding-top: 1.5px !important ;
    padding-bottom: 4px !important ;
  }
  .hints_lamp_popup_sm {
    position: absolute;
    bottom: -190px;
    zoom: 70%;
    -moz-transform: scale(0.7)
  }

  /* ArticleReadMore */

  #article-read-more{
    display: none  !important;
  }

  #article-read-more-mobile {
    display: grid  !important;
  }
  #article-read-more-mobile > .article-read-more__body{
    display: grid;
    grid-template-columns: 1fr;
  }

  .article-read-more-header__title h2{
    font-style: normal;
    font-weight: 800;
    font-size: calc(16px / 0.7);
    color: #2C476C;
    width: 60vw;
    word-wrap: break-word;

  }
  .article-read-more-header__title #like{
    width: calc(36px /0.7);
    height: calc(36px /0.7);
  }

  .article-read-more-header__title #like label{
    width: calc(36px /0.7);
    height: calc(36px /0.7);
  }

  .article-read-more-header__close svg{
    width: calc(12px /0.7);
    height: calc(12px /0.7);
  }

  /* .article-read-more-body__right-bar {
    width: 100% !important;
    max-width: 100% !important;
    padding:15px 0px;
  } */

  .article-read-more-body-main__collaps-content>div div {
    flex-wrap: wrap;
    gap: 0;
    justify-content: center;
      font-size: var(--inner-div-font-size);
      width: 100%;
  }

  .article-read-more-body-main__collaps-content>div div label {
  width: 42%;
    margin: 4px 5px;
  }

  .article-read-more-content {
    padding: 0px;
  }

  .article-read-more-body__main {
    display: grid;
    gap: 20px;
  }
  .article-read-more__header {
    padding: 20px 20px;
  }

  .articles-grid{
    zoom: 70%;
    -moz-transform: scale(0.7);
    margin-top: 20px;
    margin-right: calc(15px / 0.7);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-height: fit-content;
  }

    .hero{
      border-radius: 0px;
      padding-bottom: 0px;
      margin-bottom: 30px;
    }

    .hero__info-wrapper{
      border-radius: calc(5px / 0.7);
    }

    .minimized .hero__info-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .hero.minimized{
      position: inherit;
    }

    .hero__title {
      min-width: 160px;
      font-size: 12px;

    }


    #splide02 {
      /*margin-top: 20px !important;*/
    }

    .gallery-content {
      grid-template-columns: 90vw;
      grid-template-areas:
        "m"
        "i"
        "c";
    }

  .splide.gallery-main.splide {
    height: 350px ;
  }
  .splide.gallery-main.splide > .splide__track{
    height: 350px ;
  }
  .splide.gallery-main.splide > .splide__track > ul > li{
    height: 350px !important;
  }

  #group_content{
    height: 35px;
  }
  .timeline__arrow, .left_topic_arrow_icon, #topic_title, .right_topic_arrow_icon{
    display: none!important;
  }

  .timeline.expand .vis-panel.vis-center {
    overflow-y: unset !important;
  }
  .timeline {
    height: 66px;
  }
  /*.scroll_and_line_change {*/
  /*  bottom: 30px;*/
  /*  right: -33px;*/
  /*}*/
  .timeline .vis-custom-time div:first-child::after {
    bottom: 189px;
  }
    footer.timeline:not(.expand)>.timeline__content>.timeline_start_porsition_section {
      margin-top: 0px !important;
    }

  /*.vis-custom-time.t1{*/
  /*  top:-1px !important;*/
  /*}*/

  .mobile_main_tab{
    width: 36px !important;
    height: 36px !important;
  }

  #timeline_extend_button {
    display: none;
  }

  /*.scroll_and_line_change_buttons_plus,*/
  /*.scroll_and_line_change_buttons_minus {*/
  /*  display: none;*/
  /*}*/

  .splide__arrows{
    display: inline-block;
  }

  .ruler {
    z-index: 999 !important;
  }

  .mobile_menu_close_icon{
    /*top:50%;*/
    transform: translateY(100%);
  }
}
@media (min-width: 512px) and (max-width: 1024px) {
  .main__subitem {
    left: 40px;
  }
  .main__tab img,
  .main__tab svg {
    scale: 0.8;
  }
  .main__subitem-label.main__subitem-label_lamp svg, .lamp_header_icon svg,.hints__content_buttons_eye img{
    scale: 0.8;
  }
  .main__subitem-libtn, .main__subitem-btn_lamp {
    font-size: 13px;
    line-height: 14px;
  }
  .clock-icon {
    left: 240px!important;
      display: none;

  }
  .main__subitem-label {
    left: 265px;
  }
    .main__subitem-sort{
        display: none;
    }
    .hints__content_buttons_eye{
        display: none;
    }
  .hints.active .hints__content,.main__tab.active .main__subitem,.hints__content_item {
    width: 315px!important;
  }
}
@media (max-width: 1024px) {
  .left-side__bottom .filters.active .filters__content{
    width: 200px;
  }
  .left-side__bottom .filters__content {
    left: 43px;
  }
  .hints__content_itemswrapper_lamp{
    padding-right: 3px;
  }
  .hints__content_title{
    font-size: 12px;
  }
  .hints__content {
    grid-template-rows: 35px 1fr;
  }
  .hints__subitem_item{
    padding-left: 5px;
  }
  .hints__content_itemswrapper{
    padding: 10px 0;
  }
  .hints.active .hints__content{
    height: unset !important;
    max-height: calc(100dvh - 510px) !important;
  }

  .main__subitem-item_lamp .main__subitem-label{
    left: 260px;
  }

  .main__subitem-item_lamp{
    margin-bottom: 4px;
  }

  #group_content{
    display: none;
  }
    .lesson{
        display: none;
    }
  footer.timeline{
    height: 93px!important;
    box-shadow: 0px 0px 5px 0px #2C476C1A;
  }
  .zoom-btns{
    display: none;
  }
  .scroll_and_line_change.scroll_and_line_change_left_border{
    display: none;
  }
  .timeline__content{
    width: 100%;
  }
  .minimized .hero__info-wrapper{
    display: grid;
    grid-template-columns: 65px 1fr 25px;
  }
  .mobile_menu_buttons{
    margin-right: 10px;

  }
  .header_right_mobile:has(.active){
    display: flex;
    justify-content: right;
    contain: content;
    min-width:calc(100vw - 70px);
    padding: 5px 0;
  }
  .header_right_mobile:has(.mobile_menu_buttons_content>.active){
    contain:unset;
    min-width:unset;
  }
  .header_right_mobile:has(.mobile_menu_buttons_content>.active)  #nav-lines.active {
    animation-name: hideAnimation;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
    opacity: 0;
  }
  .mobile_menu_buttons:has(.right-side__filters.active) {
    animation-name: topToBottom;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
  }
  #nav-lines{
    z-index: 2;
    position: relative;
    opacity: 1;
    transition: 0.6s;
    padding: 5px 0;

  }
  .mobile_menu_buttons:has(.right-side__search.active),.mobile_menu_buttons:has(.right-side__toolbox.active){
    z-index: 3;
  }
  .mobile_menu_buttons_content:has(.right-side__toolbox.active){
    position: relative;
    right: -51px;
  }
  #nav-lines svg{height: 25px;}
  #nav-lines.active{
    background: rgb(44, 71, 108);
  }
  .mobile_menu_buttons {
    position: absolute;
    transition: 0.7s;
    padding: 0;
    top: 50%;
    translate: 0% -50%;
    height: 36px!important;
  }
  .mobile_menu_buttons:has(.right-side__filters.active) {
      left: -10px;
      top: 24px;
      z-index: 3;
  }
  #nav-lines .rect {
    stroke-width: 0;
    stroke: #fe0000;
    fill: transparent;
    transform-origin: 50% 50%;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  #nav-lines .cross-line {
    stroke: rgb(44, 71, 108);
    stroke-width: 0;
    transform-origin: 50% 50%;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  #nav-lines .nav-line {
    stroke: rgb(44, 71, 108);
    transform-origin: 50% 50%;
    stroke-width: 5px;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  #nav-lines.active .nav-line {
    stroke: #fff;
  }
  #nav-lines.active .cross-line {
    stroke: #fff;
    stroke-width: 5;
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  #nav-lines.active .nav-line, #nav-lines.active #nav-line-1, #nav-lines.active #nav-line-3 {
    -moz-transform: scale(0.01);
    -ms-transform: scale(0.01);
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }
  .mobile_menu_buttons_content{
    height: 100%;
  }
  .right-side__toolbox.main__tab,.right-side__filters.filters.main__tab{
    width: unset!important;
    height: 100% !important;
  }
  .right-side__toolbox, .right-side__search, .right-side__filters{
    margin-right: 0!important;
  }
  .mobile_menu_buttons_content>div>button{
    margin: 0px 5px!important;
    box-shadow: 0px 0px 5px 0px #2C476C1A;
  }
  .right-side__search-content,.right-side__search-field{
    min-height: 36px
  }
  .right-side__search-content{
    min-width: 300px;
    border-radius:5px
  }
  .right-side__search-input{
    height: 30px;
  }
  .right-side__search-content:after{
    bottom: 8px;
  }
  .right-side__filter-box{
    right: 8px;
  }
  .right-side__filter-box svg {
    width: 15px;
    height: 15px;
  }
  .mobile_menu_buttons:has(.right-side__search-content) {
    animation-name: rightToLeft;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
  }
  .mobile_menu_buttons_content:has(.active)  {
    animation-name: rightToLeftToolbox;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
  }
  .mobile_search{
    top: -1% !important;
    right: -65px!important;
    width: 36px !important;
    height: 36px !important;
    background-color: rgb(44, 71, 108);
    border-radius: 5px;
  }
  .mobile_search svg,.mobile_search svg *{
    stroke: #fff!important;
  }
  .mobile_search>span{
    height: 23px;
    width: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
  }
  .right-side__search-field{
    padding: 0 10px;
  }
  .right-side__search-field input::placeholder {
    font-size: 12px;
  }
  .pain_items{
    width: 36px !important;
    height: 36px !important;
  }
  .pain_items{
    margin-right: 7px;
  }
}

/*update 16/11/23 */
@media (max-width: 1024px) and (orientation: portrait) {
    .hints.active .hints__content.quiz__content{
        max-height: calc(100dvh - 585px) !important
    }
}
@media (max-width: 1025px) and (orientation: landscape) {
    .left-side__bottom .filters__content {
        left: -165px;
    }
    .hints.active .hints__content{
        max-height: calc(100dvh - 250px) !important;
    }
    .mobile_search {
        top: -1% !important;
        right: -46px!important;
        width: calc(25px / 0.7) !important;
        height: calc(25px / 0.7) !important;
        background-color: rgb(44, 71, 108);
        border-radius: 5px;
    }
    .right-side__search-input {
        height: 30px;
    }

    .pain_items {
        width: 100% !important;
        min-width: 35px !important;
        max-width: 37px !important;
        height: calc(26px / 0.7) !important;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.1);
        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.1);
    }
}

@media (max-width: 512px) {
    .right-side__search-content:after{
        bottom: 8px;
    }
    .right-side__search-input{
        height: 30px;
    }
    .mobile_search{
        width: 36px !important;
        height: 36px !important;
    }
    .main__tab-btn{
        width: 36px !important;
        height: 36px !important;
    }
    .mobile_menu_buttons {
        height: 37px!important;
    }

  .painter_tools_color_item_container {
    width: 222px;
  }

  .painter_tools_color_data_item {
    width: 16px;
    height: 16px;
  }

  .sss{
    border: 0;
  }
  .right-side__toolbox.main__tab.active button, .categories-collapse.main__tab.active button, .left-side__bottom .filters.active button{
    border: 0px!important;
  }
  .mobile_menu_buttons:has(.right-side__filters.active) {
    left: -10px;
    top: 20px;
    z-index: 3;
  }
  .pain_items {
    width: 100% !important;
    min-width: 35px !important;
    height: calc(26px / 0.7) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.1);
  }
  .right-side__search-content{
    min-width: calc(100vw - 125px);
    border-radius:5px
  }
  .mobile_menu_buttons{
    margin-right: 0px;

  }
  .right-side__search-content,.right-side__search-field{
    min-height: 36px;
  }
  .right-side__search-input{
    height: 30px;
  }
  .right-side__search-content:after{
    bottom: 3px;
  }
  .header{
    zoom: unset;
  }
  .header_right_mobile {
    background: #0000;
  }
  .main__left.left-side{
    zoom: unset!important;
    top: 0!important;
    left: 0!important;
    width: calc(26px / 0.7) !important;
  }
  .main__tab-btn,.filters__btn.main__tab,.hints__btn.main__tab.hints__btn_lamp{
    width: calc(26px / 0.7) !important;
    height: calc(26px / 0.7) !important;
    box-shadow: 0px 0px 5px 0px #2C476C1A;

  }
  .zoom-btn, .return-nav-btn{
    box-shadow: 0px 0px 5px 0px #2C476C1A;
    border-radius: 5px;
  }

  .main__subitem-heading, .hints__content_item{
    height: calc(26px / 0.7) !important;
  }
  .main__tab.active .main__subitem{
    width: calc(205px / 0.7);
  }
  .header_burger_mobile {
    width: calc(26px / 0.7);
    height: calc(26px / 0.7);
    box-shadow: 0px 0px 5px 0px #2C476C1A;

  }
  .main__subitem-li > div{
    padding-left: 5px;
    margin-left: 5px;
  }
  .main__subitem-libtn{
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }
  .lamp_header_icon{
    left: 235px;
  }

  .main__subitem-label{
    left: 251px;
    display: flex;
    align-items: center;
  }
  label.main__subitem-label.main__subitem-label_lamp{
    left: 235px;
    display: flex;
    align-items: center;
  }
  .clock-icon{
    left: 230px!important;
    display: none;
  }
  .main__subitem{
    left: 44px;
  }
  .hints_lamp_popup_sm{
    zoom: 0;
    bottom: -120px;
  }

  .hero{
    box-shadow: 0px 0px 5px 0px #2C476C1A;

  }


  .header_right_mobile{
    top: calc(20.5px / 0.7);
    right: calc(11px / 0.7);
  }
  .mobile_menu_burger{
    width: calc(25px / 0.7);
    height: calc(25px / 0.7);
    background-size: calc(11px / 0.7) calc(9px / 0.7);
  }
  .mobile_menu_buttons_content .right-side__search-filters{
    box-shadow: 0px 4px 20px 0px #2C476C1A;
  }
  .mobile_menu_buttons_content .right-side__search-filters>li{
    font-size: 14px;
  }
  .search_menu_filter_item button span{
    font-size: 12px;
  }
  .right-side__filters-btn img, .right-side__filters-btn svg{
    width: 13px;
    height: 14px;
  }

  .painter_tools_color_data_item{
    min-width: 25px;
    min-height: 25px;
  }
  .color_circle{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .painter_tools_size_icon_block{
    width: 25px;
    height: 25px;
  }
  .hints__content {
    grid-template-rows: 36px max-content;
  }
  .hints__content_item{
    min-height: calc(25px / 0.7) !important;
    width: calc(205px / 0.7) !important;

  }
  .hints__subitem_item{
    padding-left: 5px;
  }
  .hints.active .hints__content{
    width: calc(205px / 0.7) !important;
  }
  .main__subitem-libtn, .main__subitem-btn_lamp{
    font-family: Noto Sans Armenian;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
  }
  .hints__content_title{
    font-family: Noto Sans Armenian;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }
  .main__subitem-label.main__subitem-label_lamp svg,.lamp_header_icon svg{
    width: 16px;
    height: 16px;
  }
  .sorted_tooltip{
    left: 190px;
     width: 173px;
  }
  .sorted_tooltip_arrow {
    left: 72%;
  }
  .sorted_tooltip_content{
    margin-top: 16px;
  }
  .sorted_tooltip_title{
    margin-top: 12px;
    font-size: 12px !important;
    letter-spacing: 1.5px;
    margin-left: 13px;

  }
  .sorted_tooltip_content span{
    font-size: 10px;
  }
  .sort-board > div{
    font-size: 10px;
  }
  .sort-board[data-active="true"]{
    min-width: 173px;
  }
  .sort-board > span{
    margin: 0;
    font-size: 12px;
  }
  .sort-board[data-active="true"] {
    left: 50%;
    min-height: 110px;
  }
  .mobile_search{
    top: -1% !important;
    right: -46px!important;
    width: calc(25px / 0.7) !important;
    height: calc(25px / 0.7) !important;
    background-color: rgb(44, 71, 108);
    border-radius: 5px;
  }
  .mobile_search svg,.mobile_search svg *{
    stroke: #fff!important;
  }
  .mobile_search>span{
    height: 23px;
    width: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
  }
  .left-side__bottom .filters__content {
    left: 45px;
  }
  .left-side__bottom .filters__radio-text{
    font-size: 12px!important;
    padding-top: 1.5px !important;
    padding-bottom: 4px !important;
  }

}

@media (max-height: 512px) {
    .right-side__search-content,.right-side__search-field{
        min-height: 36px
    }
  .account .header__burger {
    width: 36px;
    height: 36px;
    left: 13px;
    font-size: 16px;
  }

  .main__tab-btn {
    width: calc(25px / 0.7) !important;
    height: calc(25px / 0.7) !important;
  }

  /*.main__tab img, .main__tab svg {*/
  /*  width: 17px;*/
  /*  height: 17px;*/
  /*  stroke: var(--main-color);*/
  /*}*/

  .main__tab-btn__title {
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
    color: #fff;
    font-size: 10px;
  }
}
@keyframes rightToLeft {
  from {
    position: absolute;
    right: 130px;
    opacity: 0.6;
  }

  to {
    position: absolute;
    right: 0px;
    opacity: 1;
  }
}
@keyframes rightToLeftToolbox {
  from {
    right: 130px;
    opacity: 0.6;
  }

  to {
    right: -51px;
    opacity: 1;
  }
}
@keyframes topToBottom {
    from {
        opacity: 0;
        top: 0px;
    }

    to {
        opacity: 1;
        top: 20px;
    }

}
@keyframes hideAnimation {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
