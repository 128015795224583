.card-body__presentation-info.presentation-info{
    display: flex;
    width: 100%;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-size: 16px;
    padding-top: 6px;
    line-height: 24px;
    color: #2C476C;
    gap: 15px;
    position: relative;
}

.card-body__presentation-info.presentation-info h4{
    white-space: nowrap;
    font-weight: 900;
}
.card-body__presentation-info.presentation-info p{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.presentation_body_content{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    font-size: 12px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 3;
}
.card-body__presentation-info.presentation-info img{
    aspect-ratio: 1/1;
}
@media screen and (min-width: 900px) and (max-width: 1800px)  {
    .card-body__presentation-info.presentation-info {
        line-height: calc(100dvh/53 );
        /*font-size: max(10px,calc(100vh/193));*/
        font-size: 12px;
        margin-bottom: 3px;
    }
}
