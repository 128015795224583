.link{
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.dot{
    position: relative;
    top:11px
}
.dot:before{
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    background-color: #29535C;
    border-radius: 100%;
}
.linkContent{
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.5px;
    color: #2D69B9;
    transition: .1s;
    cursor: pointer;
}
.link:hover .linkContent{
    opacity: 90%;
}
.singleGalleryImg , .singleGalleryVideo, .singleGalleryIframe{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 11px;
    contain: content;
    cursor: pointer;
    background-color: rgba(44, 71, 108, 0.05);
}
.singleGalleryImg img, .singleGalleryVideo video, .singleGalleryIframe iframe{
    height: 100%;
    object-fit: contain;
}
.singleGalleryImg:after, .singleGalleryVideo:after, .singleGalleryIframe:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.gallerySection{
    display: grid;
    gap: 30px;
    grid-template-rows: 30px 1fr;
}
.galleryFilterSection{
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}
.filterbtn{
    height: 30px;
    min-width: 80px;
    background: #0000;
    border-radius: 11px;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #101A29;
    border: 1px solid #2C476C;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    transition: .1s;

}
.filterInput:checked + .filterbtn{
    background: #2C476C;
    color: #FFFFFF;
}
.filterInput{
    position: absolute;
    visibility: hidden;
}
.emptyData{
    text-align: center;
    padding: 20px;
}
.rightBarBtnCenter{
    border: 1px solid #2C476C;
    border-radius: 51px;
    height: 45px;
    width: 100%;
    white-space: nowrap;
    /*overflow: hidden;*/
    text-overflow: ellipsis;
    padding: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Noto Sans Armenian', sans-serif;
    user-select: none;
}


.rightBarBtnLeft{
    border: 1px solid #2C476C;
    border-radius: 51px;
    height: 45px;
    width: 100%;
    text-transform: capitalize;
    white-space: nowrap;
    /*overflow: hidden;*/
    text-overflow: ellipsis;
    padding: 0px 20px;
    display: flex;
    text-align: left;
    gap: 10px;
    align-items: center;
    order: 2;
}
.isMain{
    order: 1;
}
.rightBarBtnLeftTitle{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rightBarBtnBoxTitle{
    margin-bottom: 5px;
    white-space: nowrap;
    text-transform: uppercase;
}

.rightBarBtnBox{
    display: flex;
    align-items: center;
    gap: 60px;
    padding: 40px 0px ;
    flex-direction: column;
}

.rightBarBtnBox:global.light-bar {
    background: #fff;
    border-radius: 10px;
    padding: 28px 0;
    border: 1px solid rgba(44, 71, 108, 0.1);
}

.rightBarBtnBoxItem{
    display: flex;
    align-items: center;
    gap: 5px;
    flex-direction: column;
    width: 90%;
}

.rightBarTagsSection{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;
    }
.rightBarTagsSectionEmpty{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 40px 0px;

}
.rightBarSectionEmpty{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 60px;
    padding: 40px 0px;
    flex-direction: column;
}
.rightBarTagItem{
    background: rgba(44, 71, 108, 0.1);
    border-radius: 100px;
    padding: 8px;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #2C476C;
    white-space: nowrap;
    text-transform: capitalize;
}
.rightBarCategoryHeaderIconBox{
    border-radius: 20px;
    width: 70px;
    height: 70px;
    display: grid;
    place-items: center;
    margin: auto;
}
.rightBarCategoryHeaderIcon{
    width: 30px;
    height: 30px;
}
.rightBarBtnLeftIcon{
    width: 20px;
    height: 20px;
}


@media screen and (max-width: 1024px) and (orientation: landscape) {
  .gallerySection {
    gap: 75px !important;
  }
  .rightBarBtnCenter {
    width: calc(80vw - 40px);
  }
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
  .gallerySection {
    gap: 75px !important;
  }
  .rightBarBtnCenter {
    width: calc(80vw - 40px);
  }
}

@media screen and (max-width: 414px) and (orientation: portrait) {
  .gallerySection {
    gap: 75px !important;
  }
  .rightBarBtnCenter {
    width: calc(80vw - 40px);
  }
}
