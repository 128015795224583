.mapboxgl-popup {
    /*transform: translate( 100px -50%)!important;*/
}
.elements-popup-box:after {
    content: "";
    width: 10px;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 0px;
    translate: -50% -50%;
    background-image: var(--icon-arrow-left);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    animation-name: arrowAnimate;
    animation-timing-function: ease;
    animation-duration: 1.3s;
    z-index: -1;
}
.elements-popup-box{
    min-width: 100px;
    min-height: 100px;
    max-height: 40vh;
    background: rgb(255 255 255);
    /* opacity: 0.2; */
    border-radius: 12px;
    padding: 8px 10px 8px 2px;
    overflow: hidden hidden;
    margin-left: 10px;
}
.elements-popup-content{
    max-height: calc(40vh - 30px);
    padding: 0 5px;
    height: 100%;
    overflow: hidden auto;
    box-shadow: 0px 0px 5px 0px #2C476C1A;

}

.elements-popup-content::-webkit-scrollbar {
    width: 3px;
}
.elements-popup-content::-webkit-scrollbar-track {
    background-color:#E8E8E8;
    border-radius: 4.4px;

}
.elements-popup-content::-webkit-scrollbar-thumb {
    background-color: #151515;
    border-radius: 4.4px;
}
.elements-popup-ul{
    display: grid;
    grid-gap: 5px;
}
.elements-popup-li{
    background: #fff;
    border-radius: 20px;
    padding: 2px 10px 2px 5px;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: .7px;
    /*color: #151515;*/
    display: grid;
    grid-template-columns: 28px max-content;
    align-items: center;
    cursor: pointer;
    grid-gap: 5px;
    width: max-content;
    transition: 0.3s;
}
.elements-popup-title{
    max-width: 300px;
    display: -webkit-box;
    -webkit-line-clamp:3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /*white-space: nowrap;*/
    font-family: 'GheaGrapalat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;
    /*color: #151515;*/

}
/*.elements-popup-icon{*/
/*    position: relative;*/
/*    display: flex;*/
/*    width: 25px;*/
/*    height: 25px;*/
/*    border-radius: 50%;*/
/*    transition: 0.3s;*/
/*}*/
.elements-popup-li:hover{
    background-color: var(--color);
}
/*.elements-popup-icon:after{*/
/*    content: "";*/
/*    position: absolute;*/
/*    width: 70%;*/
/*    height: 70%;*/
/*    left: 50%;*/
/*    top: 50%;*/
/*    translate: -50% -50%;*/
/*    background-image: var(--icon);*/
/*    background-repeat: no-repeat;*/
/*    background-size: cover;*/
/*    background-position: center;*/
/*    transition: 0.3s;*/
/*}*/
.elements-popup-icon{
    scale: 0.8;
}
.elements-popup-li:hover .elements-popup-icon:after{
    filter: brightness(10);
}
@media (max-width: 1024px) {
    .elements-popup-content{
        max-height: calc(40vh - 50px);
    }
}
@media (max-width: 512px) {
    .elements-popup-box:after{
        top: 102%;
        left: 50%;
        translate: -50% 0;
        transform: rotate(270deg);
        animation-name: arrowAnimateMobile!important;
    }
}
@keyframes arrowAnimate {
    0% {
        left: 15px;
        transform: rotateY(-180deg);
    }
    10% {
        transform: rotateY(-180deg);
        left: 15px;
    }
    20% {
        transform: rotateY(-180deg);
        left: 0px;
    }
    30% {
        left: 0px;
        transform: rotateY(-180deg);
        }
    70% {
        left: -1px;
        transform: rotateY(0);
        }
    90% {
        left: -3px;
        transform: rotateY(0);
        }
    100% {
        left: 0px;
        transform: rotateY(0);
    }
}
@keyframes arrowAnimateMobile {
    0% {
        top: 103%;
        transform: rotate(90deg);
    }
    10% {
        transform: rotate(90deg);
        top: 103%;
    }
    20% {
        transform: rotate(90deg);
        top: 102%;
    }
    30% {
        top: 102%;
        transform: rotate(90deg);
        }
    70% {
        top: 101%;
        transform: rotate(270deg);
        }
    90% {
        top: 100%;
        transform: rotate(270deg);
        }
    100% {
        top: 102%;
        transform: rotate(270deg);
    }
}