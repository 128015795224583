.mapbox-popup-draw-line-container {
    max-width: 190px;
    width: 190px;
    height: 30px;
    left: 100px;
    top: -10px;
}

.mapbox-popup-draw-line-container > .mapboxgl-popup-content  {
    border-radius: 10px;
    height: 35px;
}

.mapbox-popup-draw-line-container > .mapboxgl-popup-tip {
    display: none !important;
}

.draw-lines-popup-container {
    width: 190px;
    height: 35px;
    background-color: #2C476C;
    display: flex;
    align-items: center;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    box-shadow: 0 4px 24px 0 #292E3233;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
}

.draw-lines-popup-container_color-picker,
.draw-lines-popup-container_weight-picker {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: calc(30% + 10px);
    height: 100%;
    border-right: 2px solid #FFFFFF1F;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
}

.draw-lines-popup-container > .color-picker-popup {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    position: absolute;
    width: 190px;
    height: 35px;
    bottom: calc(100% + 5px);
    background-color: #2c476c;
    border-radius: 10px;
    box-shadow: 0px 4px 24px 0px #292E3233;
}

.draw-lines-popup-container_color-picker > .current-picked-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.draw-lines-popup-container_color-picker > .current-picked-color > svg {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.line_tool_color_data_item > .available-color {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}

.line_draw_tools_color_item_container {
    padding: 10px 12px;
    background: #fff;
    position: absolute;
    width: 120%;
    /*height: -moz-fit-content;*/
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 1;
    bottom: 90px;
    right: -7px;
    box-shadow: 0 4px 24px 0 #292E3233;
}

.line_draw_tools_color_item {
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.line_draw_tools_color_item:hover {
    border: 2px solid #fff;
}

.line_draw_tools_color_item_container > span {
    width: 22px;
    height: 22px;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
    left: 89%;
    top: 98%;
    background: #fff;
}

.draw-lines-popup-container_actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
    height: 100%;
    border-radius: 0 5px 5px 0;
    padding: 5px;
}

.draw-lines-popup-container_actions > * {
    margin: 0 3px;
    cursor: pointer;
}

.draw-lines-popup-container_weight-picker > .current-line-weight {
    width: 35px;
    text-align: center;
    font-size: 14px;
}

.triangle-icon-wrapper {
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.triangle-icon-wrapper > .triangle-icon {
    width: 12px;
    height: 6px;
    transition: all 0.3s ease;
    transform: rotate(180deg);
}

.triangle-icon-wrapper > .triangle-icon.active {
    transform: rotate(0deg);
}

.dropdown-content-wrapper {
    width: 100%;
    position: relative;
}

.dropdown-content {
    position: absolute;
    background-color: #ffffff;
    width: 58px;
    top: 35px;
    max-height: 100px;
    overflow-y: auto;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 0 0 7px 10px;
}

.dropdown-content span {
    color: #6E6B7B;
    padding: 2px 10px;
    text-decoration: none;
    display: block;
}

.dropdown-content span:hover {
    background-color: #f1f1f1;
}

.dropdown-content span.selected {
    font-weight: bold;
}

.dropdown-content::-webkit-resizer {
    display: none;
}

.dropdown-content::-webkit-scrollbar {
    width: 7px;
    padding: 0px 2px;
}

.dropdown-content::-webkit-scrollbar-track {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}

.dropdown-content::-webkit-scrollbar-thumb {
    width: 7px !important;
    background-color: #2c476c;
    outline: none;
    border-radius: 5px;
}

.mapbox-hover-popup-draw-line-container {
    left: 10px;
    top: 5px;
}

.hover-popUp-container-line-string {
    position: absolute;
    right: 20px;
    bottom: -5px;
    width: max-content;
    border-radius: 15px;
    padding: 10px;
    background: #fff;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    animation: anim 0.3s;
    transition: 0.3s ease-in-out;
}

.hover-popUp-container-line-string > .hover-popUp-text {
    height: fit-content;
    font-size: 0.8rem;
    margin: 0;
}

@keyframes anim {
    0% {
     opacity: 0;
    }
    100% {
     opacity: 1;
    }
}